import React, { useEffect, useContext, useRef } from "react";
import Seo from "../../components/seo/seo";
import Layout from "../../components/layout/layout";
import PageBanner from "../../components/common/PageBanner";
import Clients from "../../components/common/Clients";
import InteractiveVideo from "../../components/common/InteractiveVideo";
// import image from "../../assets/img/products/interactive-animations.png";
import { GlobalStateContext } from "../../context/GlobalContextProvider";
// import { TweenLite, Power3 } from "gsap";
import { StaticImage } from "gatsby-plugin-image";

const InteractiveAnimations = () => {
  const state = useContext(GlobalStateContext);
  let container = useRef(null);

  useEffect(() => {
    if (!state.showPageLoading) {
      const cn = container.current;
      setTimeout(() => {
        cn.classList.remove("hide-container");
      }, state.containerAnimationDelay * 1000);
    }
  }, [state.showPageLoading, state.containerAnimationDelay]);

  return (
    <Layout>
      <Seo
        title='Interactive Animations | VIRSAT - Virtual Reality Safety Training'
        description='Recognising life-threatening hazards in an interactive and visual way makes safety communication engaging and knowledge testing easy.'
      />
      <PageBanner
        title='Interactive animations'
        subtitle='Check your knowledge'
      />
      <section
        className='main-container single-product hide-container'
        ref={container}
      >
        <div className='row top-pad bottom-pad fd-col less-mar'>
          <div className='column'>
            <p className='medium reduce_width dark no-mar center'>
              Recognising life-threatening hazards in an interactive and visual
              way makes safety communication engaging and knowledge testing
              easy.
            </p>
            <hr className='site-border' />
          </div>
          <div className='column details-container'>
            <div className='details'>
              <p>
                Workers can develop and test their practical safety awareness
                with real-life animations based on actual industry incidents. It
                takes no more than a smartphone to participate.
              </p>
              <p>
                Workers of all backgrounds can participate easily as our
                language-free<sup>®</sup> interaction method is used. The
                interaction data helps to identify common knowledge gaps and
                confirm knowledge retention.
              </p>
            </div>
            <div className='image-container taller'>
              {/* <img src={image} alt='Pre-job discussions' /> */}
              <StaticImage
                src='../../assets/img/products/interactive-animations.png'
                alt='Interactive animations'
                placeholder='blurred'
                objectFit
              />
            </div>
          </div>
        </div>
      </section>
      <InteractiveVideo prodPage />
      <Clients />
    </Layout>
  );
};

export default InteractiveAnimations;
